/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import dashboard from './dashboard';
import appsAndPages from './apps-and-pages';
import others from './others';
import chartsAndMaps from './charts-and-maps';
import uiElements from './ui-elements';
import formAndTable from './forms-and-table';

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

export default [
  // ...dashboard,

  {
    header: 'Staff',
    permission: 'ui.nav.staff',
  },
  {
    title: 'Students',
    icon: 'UserIcon',
    route: 'apps-students-list',
    permission: 'ui.nav.staff.students',
  },
  {
    title: 'Hall of Shame',
    faIcon: 'fa-solid fa-face-frown',
    route: 'apps-students-blacklist',
    permission: 'ui.nav.staff.blacklist',
  },

  {
    header: 'Roster',
    permission: 'ui.nav.roster',
  },
  {
    title: 'Pathways',
    icon: 'BookIcon',
    route: 'apps-roster-pathway-list',

    permission: 'ui.nav.roster.pathways',
  },
  {
    title: 'Sessions',
    icon: 'CalendarIcon',
    route: 'apps-roster-session-list',

    permission: 'ui.nav.roster.sessions',

    children: [
      {
        title: 'List',
        route: 'apps-roster-session-list',

        permission: 'ui.nav.roster.sessions.list',
      },
      {
        title: 'Live',
        route: 'apps-roster-session-live-list',

        permission: 'ui.nav.roster.sessions.live',
      },
      {
        title: 'Upcoming',
        route: 'apps-roster-session-upcoming-list',

        permission: 'ui.nav.roster.sessions.upcoming',
      },
    ],
  },
  {
    title: 'Courses',
    icon: 'GridIcon',
    route: 'apps-roster-course-list',

    permission: 'ui.nav.roster.courses',
  },
  {
    title: 'Subjects',
    icon: 'BookOpenIcon',
    route: 'apps-roster-subject-list',

    permission: 'ui.nav.roster.subjects',
  },
  {
    title: 'Calendars',
    icon: 'CalendarIcon',
    route: 'apps-roster-calendar-list',

    permission: 'ui.nav.roster.calendars',
  },
  {
    title: 'Profiles',
    icon: 'UsersIcon',
    route: 'apps-roster-profile-list',

    permission: 'ui.nav.roster.profiles',
  },

  {
    header: 'Placement',
    permission: 'ui.nav.placement',
  },
  {
    title: 'Monday',
    icon: 'SendIcon',
    route: 'apps-placement-monday',

    permission: 'ui.nav.placement.monday',

    children: [
      {
        title: 'Templates',
        route: 'apps-placement-monday-template-list',
        permission: 'ui.nav.placement.monday.templates',
      },
    ],
  },

  {
    header: 'Accounting',
    permission: 'ui.nav.accounting',
  },
  {
    title: 'Receivable',
    faIcon: 'fa-solid fa-dollar-sign',

    permission: 'ui.nav.accounting.receivable',

    children: [
      {
        title: 'Accounts',
        route: 'apps-accounting-receivable-account-list',

        permission: 'ui.nav.accounting.receivable.account.list',
      },
      {
        title: 'Cohorts',
        route: 'apps-accounting-receivable-cohort-list',

        permission: 'ui.nav.accounting.receivable.cohort.list',
      },
      {
        title: 'Templates',
        route: 'apps-accounting-receivable-template-list',

        permission: 'ui.nav.accounting.receivable.template.list',
      },
      {
        title: 'Reconcile',
        route: 'apps-accounting-receivable-reconcile',

        permission: 'ui.nav.accounting.receivable.reconcile',
      },
    ],
  },
  {
    title: 'Report',
    icon: 'ArchiveIcon',
    route: 'apps-report-list',

    permission: 'ui.nav.accounting.report',
  },

  {
    header: 'Management',
    permission: 'ui.nav.management',
  },
  {
    title: 'User',
    icon: 'UserIcon',
    route: 'apps-users-list',

    permission: 'ui.nav.management.users',
  },
  {
    title: 'Jobs',
    icon: 'DownloadCloudIcon',
    route: 'apps-job-list',

    permission: 'ui.nav.management.jobs',
  },

  {
    header: 'Personal',
    permission: 'ui.nav.personal',
  },
  {
    title: 'Account Settings',
    icon: 'SettingsIcon',
    route: 'pages-account-setting',
    permission: 'ui.nav.personal.account_settings',
  },

  {
    header: 'System',
    permission: 'ui.nav.system',
  },
  {
    title: 'Audit',
    icon: 'EyeIcon',
    route: 'apps-audit-audit-list',

    permission: 'ui.nav.system.audit',
  },
];
